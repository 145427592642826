import BaseResource from './base';
import PollingService from '@/utils/poll';

const JOB_STATUS_WIP = 'wip';
const JOB_STATUS_ERR = 'error';
const JOB_STATUS_DONE = 'done';

export default class VenuesResource extends BaseResource {
  async create(newVenue) {
    const res = await this.http.post('v1/venues', newVenue);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/venues/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/venues/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/venues');

    return Promise.resolve(res.data.data);
  }

  async update(venue) {
    const res = await this.http.put(`v1/venues/${venue.id}`, venue);

    return Promise.resolve(res.data.data);
  }

  async getLanguages(id) {
    const endpoint = `v1/venues/${id}/languages`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async addLanguage(venueId, languageId) {
    const endpoint = `v1/venues/${venueId}/languages/${languageId}`;
    const res = await this.http.post(endpoint);

    return Promise.resolve(res.data.data);
  }

  async deleteLanguage(venueId, venueLanguageId) {
    const endpoint = `v1/venues/${venueId}/languages/${venueLanguageId}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async invalidRoutes(id) {
    const endpoint = `v1/venues/${id}/routes`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async migrateMap(id) {
    const endpoint = `v2/venues/${id}/map-migrate`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async geocodeAllLocations(venueId) {
    const endpoint = `v2/venues/${venueId}/locations/geocode`;
    const res = await this.http.post(endpoint);
    const jobId = res.data.data?.job_id;
    const jobStatus = res.data.data?.status;

    if (res.status >= 400 || !jobId || !jobStatus) {
      const err = new Error('unable to start geocoding job');
      return Promise.reject(err);
    }


    return new Promise((resolve, reject) => {
      const MAX_WAIT_MS = 1000 * 60 * 10;
      const masterTimeout = setTimeout(async () => {
        reject(new Error('max wait time for geocoding jobs to complete exceeded.'));
      }, MAX_WAIT_MS);

      const pollingService = new PollingService(this.http);
      const pollEndpoint = `v2/venues/${venueId}/locations/geocode/${jobId}`;
      const POLL_INTERVAL_MS = 5000;
      pollingService.startPolling(pollEndpoint, {
        interval: POLL_INTERVAL_MS,
        retries: 3,
        onSuccess: (data, stop) => {
          const pollStatus = data.data.status;
          if (!pollStatus) {
            const err = new Error('unable to get geocoding job status');
            stop();
            return reject(err);
          }
          if (pollStatus === JOB_STATUS_WIP) {
            // WIP noop
          }
          if (pollStatus === JOB_STATUS_DONE) {
            clearTimeout(masterTimeout);
            stop();
            resolve({ status: pollStatus });
          }
          if (pollStatus === JOB_STATUS_ERR) {
            clearTimeout(masterTimeout);
            const errs = data.errors;
            stop();
            resolve({ status: pollStatus, errors: errs });
          }
          return true;
        },
        onError: (err) => reject(err),
      });
    });
  }
}
