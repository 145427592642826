import BaseResource from './base';
import { convertDateRangePickerDates } from '@/services/skyos/metrics';

export default class ReportsResource extends BaseResource {
  constructor(token) {
    super(token);
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  async adplays(params) {
    try {
      const res = await this.http.get('v1/metrics/reports/adplays', {
        params,
      });

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async programmatic(params) {
    try {
      const res = await this.http.get('v1/metrics/reports/programmatic', {
        params,
      });

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async kioskInteractions(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    try {
      const res = await this.http.get('v1/metrics/reports/kioskInteractions', {
        params: {
          venue_id: config.venue_id,
          start,
          end,
        },
      });

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async venueDataReport(config) {
    try {
      const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
      const res = await this.http.get('v1/metrics/reports/venueDataReport', {
        params: {
          venue_id: config.venueId,
          start,
          end,
        },
      });
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createRawAdplayReport(data) {
    try {
      const res = await this.http.post('v2/metrics/adplays/report/raw', data);
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchRawAdplayReports() {
    try {
      const res = await this.http.get('v2/metrics/adplays/report/raw');
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async generateAdplayDownloadUrl(id) {
    try {
      const res = await this.http.get(`v2/metrics/adplays/report/raw/${id}/download`);
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
