const validPollingOpts = (opts) => {
  // required opts
  if (!opts.interval) {
    console.error('invalid pollingOpts: interval required');
    return false;
  }
  if (typeof opts.interval !== 'number') {
    console.error('invalid pollingOpts: interval must be a number');
    return false;
  }
  if (!opts.retries) {
    console.error('invalid pollingOpts: retries required');
    return false;
  }
  if (typeof opts.retries !== 'number') {
    console.error('invalid pollingOpts: retries must be a number');
    return false;
  }
  if (!opts.onSuccess) {
    console.error('invalid pollingOpts: onSuccess required');
    return false;
  }
  if (typeof opts.onSuccess !== 'function') {
    console.error('invalid pollingOpts: onSuccess must be a function');
    return false;
  }

  // optional opts
  if (opts.onError) {
    if (typeof opts.onError !== 'function') {
      console.error('invalid pollingOpts: onError must be a function');
      return false;
    }
  }

  return true;
};

export default class PollingService {
  timerId = undefined;

  http = undefined;

  constructor(http) {
    this.http = http;
  }

  startPolling(url, opts) {
    const validOpts = validPollingOpts(opts);
    if (!validOpts) {
      console.error('invalid opts passed to startPolling');
      return;
    }

    let retryCount = 0;

    const stopPolling = () => {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = undefined;
      }
    };

    const poll = async () => {
      try {
        const res = await this.http.get(url);
        if (opts.onSuccess && typeof opts.onSuccess === 'function') {
          opts.onSuccess(res.data, stopPolling);
        }

        retryCount = 0;
      } catch (e) {
        if (opts.onError && typeof opts.onError === 'function') {
          opts.onError(e);
        }

        retryCount += 1;
        if (opts.retries && retryCount >= opts.retries) {
          console.error('pollingservice max retries reached. stopping polling');
          stopPolling();
        }
      }
    };

    this.timerId = setInterval(poll, opts.interval);
  }
}
