<template>
  <div
    v-shortkey="['ctrl', '/']"
    class="d-flex flex-grow-1 default-layout"
    @shortkey="onKeyup"
  >
    <v-overlay
      :value="applicationIsLoading"
      opacity="1.0"
      color="#06121d"
      :style="{ 'z-index': 10 }"
    >
      <div class="text-center">
        <v-img
          class="w-full"
          max-height="50"
          contain
          src="/images/logos/roveiq-smartlink-transition.png"
        />
        <h4 class="mt-1" style="font-family: futura-pt-bold, sans-serif !important;">
          Application loading...
        </h4>
        <v-progress-circular
          class="mt-1"
          indeterminate
          size="64"
        />
      </div>
    </v-overlay>
    <ad-blocker-dialog />
    <delete-dialog />
    <div v-if="!applicationIsLoading" class="fill-width">
      <v-navigation-drawer
        v-model="drawer"
        app
        floating
        class="elevation-1 dark-bg drawer"
        style="z-index: 17"
        :right="$vuetify.rtl"
        :light="menuTheme === 'light'"
        :dark="menuTheme === 'dark'"
      >
        <!-- Navigation menu info -->
        <template #prepend>
          <div class="pa-2">
            <v-img
              class="w-full"
              fill
              max-height="120"
              src="/images/logos/roveiq-smartlink-transition.png"
            />
          </div>
        </template>

        <main-menu :menu="menu" />

        <!-- Admin menu -->
        <v-divider v-if="userIsAdmin" />
        <main-menu
          v-if="userIsAdmin"
          :menu="admin"
        />

        <!-- Navigation menu footer -->
        <template #append>
          <!-- Footer navigation links -->
          <div class="pa-1">
            <v-icon>mdi-antenna</v-icon>
            <changelog />
          </div>

          <v-dialog
            v-model="supportDialog"
            max-width="50-vw"
            width="30vw"
          >
            <template #activator="{ on, attrs }">
              <div class="pa-1">
                <v-icon>mdi-help-circle-outline</v-icon>
                <v-btn
                  v-bind="attrs"
                  text
                  plain
                  x-large
                  :input-value="true"
                  v-on="on"
                >
                  Support
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title class="white--text blue darken-2 align-center">
                Support
                <v-spacer />
                <v-btn
                  icon
                  color="white"
                  outlined
                  @click="close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider />

              <v-card-text>
                <div class="text-h5 pa-3 text-center">
                  Support has moved.
                  You can now request support at our new support email:
                  <a href="mailto:support@roveiq.com">support@roveiq.com</a>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>

          <div class="pa-1 text-center">
            <v-btn
              v-for="(item, index) in footer"
              :key="index"
              :href="item.href"
              small
              :target="item.target"
              text
            >
              {{ item.key ? $t(item.key) : item.text }}
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <!-- Toolbar -->
      <v-app-bar
        app
        :color="isToolbarDetached ? 'surface' : undefined"
        :flat="isToolbarDetached"
        :light="toolbarTheme === 'light'"
        :dark="toolbarTheme === 'dark'"
      >
        <v-card
          class="flex-grow-1 d-flex"
          :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
          :flat="!isToolbarDetached"
        >
          <div class="d-flex flex-grow-1 align-center">
            <!-- search input mobile -->
            <v-autocomplete
              v-if="showSearch"
              v-model="searchItem"
              append-icon="mdi-close"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              solo
              flat
              autofocus
              :search-input.sync="search"
              :items="searchItems"
              hide-no-data
              item-text="display"
              item-value="link"
              no-filter
              @click:append="showSearch = false"
            />

            <div v-else class="d-flex flex-grow-1 align-center">
              <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

              <v-select
                v-if="shouldShowVenueSelector"
                v-model="selectedVenue"
                :items="venuesAlpha"
                item-text="name"
                item-value="id"
                label="Select a venue"
                hide-details
                outlined
                dense
                rounded
                class="mx-1"
                @change="changeSelectedVenue"
                @focus="() => this.$store.commit('app/toggleIsVenueSelectorOpen', true)"
                @blur="() => this.$store.commit('app/toggleIsVenueSelectorOpen', false)"
              />

              <v-spacer class="d-none d-lg-block" />

              <!-- search input desktop -->
              <v-autocomplete
                ref="search"
                v-model="searchItem"
                class="mx-1 hidden-xs-only"
                :placeholder="$t('menu.search')"
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                rounded
                dense
                :items="searchItems"
                :search-input.sync="search"
                hide-no-data
                item-text="display"
                item-value="link"
                no-filter
              />

              <v-spacer class="d-block d-sm-none" />

              <v-btn
                class="d-block d-sm-none"
                icon
                @click="showSearch = true"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

              <toolbar-user />
            </div>
          </div>
        </v-card>
      </v-app-bar>

      <v-main>
        <v-container class="fill-height" :fluid="!isContentBoxed">
          <v-layout>
            <v-overlay
              :value="isChangingVenues"
              color="#06121d"
            >
              <div class="text-center">
                <v-img
                  class="w-full"
                  max-height="50"
                  contain
                  src="/images/logos/roveiq-smartlink-transition.png"
                />
                <h4 class="mt-1">
                  Changing venues...
                </h4>
                <v-progress-circular
                  class="mt-1"
                  indeterminate
                  size="64"
                />
              </div>
            </v-overlay>
            <transition
              enter-active-class="animate__animated animate__fadeInRight"
              leave-active-class="animate__animated animate__fadeOutRight"
            >
              <previewer v-if="showPreviewer" />
            </transition>
            <span v-if="userIsAdmin">
              <span v-if="chatVenueId && chatVenueId > 0">
                <roveiq-chat-embed :venueId="chatVenueId" bottom="30px" />
              </span>
            </span>
            <slot v-if="ready" />
          </v-layout>
        </v-container>

        <v-footer app inset>
          <v-spacer />
          <div class="overline">
            RoveIQ Portal Version {{ versionNumber }} -
            Copyright © 2018-{{ currentYear }} Smartlink Ventures dba RoveIQ, Inc. All rights Reserved.
            <router-link to="/tos">
              Terms of Use
            </router-link>
            |
            <router-link to="/privacy">
              Privacy Policy
            </router-link>
          </div>
        </v-footer>

        <v-snackbar
          v-if="mimicEnabled"
          v-model="showMimic"
          :timeout="-1"
        >
          Mimic Enabled for: {{ user.email }}. <br>Logged in as {{ mimicUser.email }}.

          <template #action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="endMimic"
            >
              Stop Mimic
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

import MainMenu from '@/components/navigation/MainMenu';
import Changelog from '@/components/widgets/Changelog';
import Previewer from '@/components/widgets/Previewer';
import ToolbarUser from '@/components/toolbar/ToolbarUser';
import AdBlockerDialog from '@/components/dialogs/AdBlockerDialog';
import DeleteDialog from '@/components/dialogs/DeleteDialog'; // navigation menu configurations
import config from '../configs';
import pJson from '../../package.json';

export default {
  components: {
    DeleteDialog,
    MainMenu,
    Previewer,
    ToolbarUser,
    Changelog,
    AdBlockerDialog,
  },
  data() {
    return {
      drawer: true,
      ready: false,
      showSearch: false,
      search: '',
      searchItem: null,
      searchItems: [],
      showMimic: true,
      selectedVenue: undefined,
      menu: config.navigation.menu,
      footer: config.navigation.footer,
      admin: config.navigation.admin,
      lastLetter: null,
      supportDialog: false,
      chatVenueId: null,
    };
  },
  computed: {
    ...mapState('app', ['product', 'isContentBoxed', 'menuTheme', 'toolbarTheme', 'isToolbarDetached']),
    ...mapGetters({
      applicationIsLoading: 'content/applicationIsLoading',
      isChangingVenues: 'content/venues/isChangingVenues',
      venues: 'content/venues/all',
      activeVenue: 'content/venues/active',
      activeVenueId: 'content/venues/activeVenueId',
      user: 'auth/user',
      token: 'auth/token',
      mimicEnabled: 'auth/mimicEnabled',
      mimicUser: 'auth/mimicUser',
      showPreviewer: 'app/previewer/show',
      enableRoveMaps: 'content/venues/enableRoveMaps',
    }),
    venuesAlpha() {
      return _.sortBy(this.venues, 'name');
    },
    userIsAdmin() {
      if (this.user) {
        return this.user.admin;
      }
      return false;
    },
    versionNumber() {
      return pJson.version;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    shouldShowVenueSelector() {
      return this.$route.meta.showVenueSelector;
    },
  },
  watch: {
    activeVenue(newVal) {
      this.selectedVenue = newVal;
      this.updateNavigation();

      if (newVal !== this.chatVenueId) {
        this.chatVenueId = undefined;
        setTimeout(() => {
          this.chatVenueId = this.activeVenueId;
        }, 500);
      }
    },
    search(newVal) {
      // don't do anything if something has been selected
      if (this.searchItem) {
        return;
      }

      if (this.lastLetter) {
        clearTimeout(this.lastLetter);
      }

      this.lastLetter = setTimeout(async () => {
        if (newVal && newVal.length >= 4) {
          this.updateSearch(newVal);
        } else {
          this.searchItems = [];
        }
      }, 500);
    },
    async searchItem(newval) {
      if (newval) {
        this.searchItems = [];
        this.searchItem = null;

        if (newval.includes('?')) {
          const pieces = newval.split('?');
          const urlParams = new URLSearchParams(pieces[1]);
          const newVenueId = parseInt(urlParams.get('venue_id'), 10);

          if (newVenueId !== this.activeVenueId) {
            await this.$store.dispatch('content/venues/updateIsChangingVenues', true);
            localStorage.setItem('activeVenueId', newVenueId);
            await this.$store.dispatch('content/venues/updateActive', newVenueId);
            await this.$store.dispatch('content/updateActiveContent');
            await this.$store.dispatch('content/venues/updateIsChangingVenues', false);
          }
        }
        await this.$router.push(newval);
      }
    },
  },

  async mounted() {
    if (!this.token || !this.user || !this.venues) {
      await this.fetchInitialData();
    }
    if (this.user?.password_reset_required) {
      if (this.$route.path !== '/auth/update-password') {
        return this.$router.push('/auth/update-password');
      }
    }

    this.selectedVenue = this.activeVenue;

    await this.checkActiveVenueIdQueryParam();
    this.updateNavigation();

    this.ready = true;
    return Promise.resolve();
  },

  methods: {
    close() {
      this.supportDialog = false;
    },

    updateNavigation() {
      const items = _.cloneDeep(config.navigation.menu);

      if (this.selectedVenue) {
        const legacyMode = _.get(this.selectedVenue.settings, 'legacy_mode');

        if (
          legacyMode
          && items[1]
          && items[1].items
          && items[1].items[1]
          && items[1].items[1].items
          && items[1].items[1].items[10]
        ) {
          items[1].items[1].items[10].items.push({
            icon: 'mdi-view-quilt',
            key: '',
            link: '/content/settings/legacyui',
            text: 'Legacy User Interface',
          });
        }
      }

      const newMenu = [];
      items[0].items.forEach((parentItem) => {
        // don't add item to menu
        if (parentItem.roveMap && !this.enableRoveMaps) {
          return;
        }
        if (parentItem.items && parentItem.items.length > 0) {
          parentItem.items = parentItem.items.filter((childItem) => (!childItem.roveMap || (childItem.roveMap && this.enableRoveMaps)));

          if (!this.userIsAdmin) {
            parentItem.items = parentItem.items.filter((childItem) => (!childItem.isAdmin));
          }
        }
        newMenu.push(parentItem);
      });

      this.menu = [{ items: newMenu }];
    },

    onKeyup() {
      this.$refs.search.focus();
    },

    async changeSelectedVenue() {
      const link = `${this.$route.path}?venue_id=${this.selectedVenue}`;

      window.history.pushState({}, null, link);

      await this.$store.dispatch('content/venues/updateIsChangingVenues', true);
      localStorage.setItem('activeVenueId', this.selectedVenue);
      await this.$store.dispatch('content/venues/updateActive', this.selectedVenue);
      await this.$store.dispatch('content/updateActiveContent');
      await this.$store.dispatch('content/venues/updateIsChangingVenues', false);
    },

    async checkActiveVenueIdQueryParam() {
      const venueIdParam = _.get(this, '$route.query.venue_id', false);

      if (venueIdParam) {
        const venueId = parseInt(venueIdParam, 10);

        if (venueId !== this.activeVenueId) {
          await this.$store.dispatch('content/venues/updateIsChangingVenues', true);
          localStorage.setItem('activeVenueId', venueId);
          await this.$store.dispatch('content/venues/updateActive', venueId);
          await this.$store.dispatch('content/updateActiveContent');
          await this.$store.dispatch('content/venues/updateIsChangingVenues', false);
        }
      }
    },

    async fetchInitialData() {
      try {
        await this.$store.dispatch('auth/fetchDataForReturningUser');
        this.$analytics.setUser(this.$store.getters['auth/user']);
      } catch (e) {
        this.$router.push('/auth/signin');
      }
    },

    async endMimic() {
      await this.$store.dispatch('auth/endMimic');
    },

    async updateSearch(searchString) {
      const results = await this.$store.dispatch('app/search', searchString);

      if (results) {
        this.searchItems = results.map((item) => {
          item.link = this.itemLink(item);

          return item;
        });
      } else {
        this.$store.dispatch('app/showError', {
          message: 'Error while searching',
          error: {
            message: '',
          },
        });
      }

      // eslint-disable-next-line default-case
      switch (searchString.toLowerCase()) {
        case 'locations':
          this.searchItems.push({
            link: `/content/locations?venue_id=${this.activeVenueId}`,
          });
          break;
      }
    },

    itemLink(searchItem) {
      if (!searchItem) {
        return '';
      }

      const {
        model,
        venue_id,
        id,
      } = searchItem;

      let route = '/';

      // eslint-disable-next-line default-case
      switch (model) {
        case 'venue':
          route = `/settings/venue?venue_id=${venue_id}`;
          break;
        case 'kiosk':
        case 'screen':
          route = `/displays/${model}s/${id}/edit?venue_id=${venue_id}`;
          break;
        case 'location':
        case 'events':
          route = `/content/${model}s/${id}/edit?venue_id=${venue_id}`;
          break;
        case 'amenity':
          route = `/content/amenities/${id}/edit?venue_id=${venue_id}`;
          break;
        case 'campaign':
          route = `/ads/campaigns/${id}/schedule`;
          break;
      }

      return route;
    },
  },
};
</script>

<style scoped>
.dark-bg {
  background-color: #06121d;
}

.slide-enter-active {
  transition: all .3s ease;
}

.slide-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.footer-nav-link {
  text-decoration: none;
  margin: 0 1rem;
  font-weight: bold;
  color: #edf0f2;
}

#announcement-banner {
  font-family: futura-pt-bold, sans-serif !important;
  background-color: var(--v-primary-base);
  color: white;
}

.default-layout h1, h2, h3, h4, h5, h6 {
  font-family: futura-pt-bold, sans-serif;
}

.drawer {
  font-family: futura-pt-bold, sans-serif;
}
</style>
